import {Link, useLocation, useSearchParams} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {FormTextField} from '../../ui/forms/input-field/text-field/text-field';
import {Button} from '../../ui/buttons/button';
import {Form} from '../../ui/forms/form';
import {LoginPayload, useLogin} from '../requests/use-login';

import {CustomMenuItem} from '../../menus/custom-menu';
import {useRecaptcha} from '../../recaptcha/use-recaptcha';
import {RegisterPayload, useRegister} from '../requests/use-register';

import {FormCheckbox} from '../../ui/forms/toggle/checkbox';
import {LinkStyle} from '../../ui/buttons/external-link';
import {SocialAuthSection} from './social-auth-section';
import {AuthLayout} from './auth-layout/auth-layout';
import {Trans} from '../../i18n/trans';
import {StaticPageTitle} from '../../seo/static-page-title';
import {useContext, useState} from 'react';
import Modal from 'react-modal';
import {
  SiteConfigContext,
  SiteConfigContextValue,
} from '../../core/settings/site-config-context';
import {useSettings} from '../../core/settings/use-settings';

interface Props {
  onTwoFactorChallenge: () => void;
}
export function LoginPage({onTwoFactorChallenge}: Props) {
  const [searchParams] = useSearchParams();
  const {pathname} = useLocation();

  const isWorkspaceLogin = pathname.includes('workspace');
  const isWorkspaceRegister = pathname.includes('workspace');

  const searchParamsEmail = searchParams.get('email') || undefined;

  const {branding, registration, site, social} = useSettings();
  const siteConfig = useContext(SiteConfigContext);


  const {auth} = useContext(SiteConfigContext);
  const {verify, isVerifying} = useRecaptcha('register');


  const demoDefaults =
    site.demo && !searchParamsEmail ? getDemoFormDefaults(siteConfig) : {};
  const form = useForm<LoginPayload>({
    defaultValues: {remember: true, email: searchParamsEmail, ...demoDefaults},
  });

  const registerForm = useForm<RegisterPayload>({
    defaultValues: {email: searchParamsEmail},
  });


  Modal.setAppElement('#root');
  const [isNewLoginModalOpen, setIsNewLoginModalOpen] = useState(false);
  const [isExistingRegisterModalOpen, setIsExistingRegisterModalOpen] = useState(false);

  const openNewLoginModal = () => {
    setIsNewLoginModalOpen(true);
  };

  const closeNewLoginModal = () => {
    setIsNewLoginModalOpen(false);
  };

  const openExistingRegisterModal = () => {
    setIsExistingRegisterModalOpen(true);
  };

  const closeExistingRegisterModal = () => {
    setIsExistingRegisterModalOpen(false);
  };
  const login = useLogin(form);

  const register = useRegister(registerForm);

  const heading = (
    <Trans message="Happening now" />
  );
  const subheading = (
    <Trans message="Join today." />
  );

  const message = !registration.disable && (
    <Trans
      values={{
        a: parts => (
          <Link className='py-10 flex items-center justify-center px-4 space-x-2 transition-colors duration-300 border bg-primary text-white rounded-xl group hover:bg-blue-500 focus:outline-none' to="/register">
            {parts}
          </Link>
        ),
      }}
      message="<a>Create Account</a>"
    />
  );

  const logimessage = (
    <Trans
      values={{
        a: parts => (
          <Link className={LinkStyle} to="/login">
            {parts}
          </Link>
        ),
      }}
      message="Already have an account? <a>Sign in.</a>"
    />
  );

  const isInvalid = !!Object.keys(form.formState.errors).length;

  return (
    <AuthLayout heading={heading} subheading={subheading} /* logimessage={logimessage} message={message}*/>
      <StaticPageTitle>
        <Trans message="Login" />
      </StaticPageTitle>
      <SocialAuthSection
        dividerMessage={
          social.compact_buttons ? (
            <Trans message="OR" />
          ) : (
            <Trans message="OR" />
          )
        }
      />
      <Button
          className="block w-full rounded-xl"
          type="submit"
          variant="flat"
          color="primary"
          size="md"
          onClick={openExistingRegisterModal}
        >
          <Trans message="Create account" />
        </Button>
          <p className="mt-6 text-sm text-gray-700">
            Read our <a href="#" className="underline">terms</a> and <a href="#" className="underline">conditions</a>
          </p>
          <h5 className='mt-60 mb-30 text-xl text-gray-700'>Already have an account?</h5>

      <Button
          className="block w-full border border-primary text-primary bg-white rounded-xl px-4 py-2 transition-colors duration-300 hover:bg-primary hover:text-white focus:outline-none focus:ring focus:border-blue-300"
          type="submit"
          variant="flat"
          color="primary"
          size="md"
          onClick={openNewLoginModal}
        >
          <Trans message="Sign in" />
        </Button>

          <Modal
        isOpen={isNewLoginModalOpen}
        onRequestClose={closeNewLoginModal}
        className="modal-content rounded-xl flex flex-col gap-20 w-full xl:w-1/3 lg:w-1/3 md:w-full sm:w-full"
        overlayClassName="modal-overlay w-full bg-black bg-opacity-70"
        style={{
          overlay: {
            zIndex: 1000,
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            position: 'fixed',
          },
          content: {
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#fff',
            position: 'fixed',
            zIndex: '9999',
          },
        }}
      >
        {/* Content for the new login modal */}
        <button
          className="px-10 absolute top-4 left-4 text-gray-500 hover:text-gray-700"
          onClick={closeNewLoginModal}
        >
          X
        </button>
      <Form
        form={form}
        onSubmit={payload => {
          login.mutate(payload, {
            onSuccess: response => {
              if (response.two_factor) {
                onTwoFactorChallenge();
              }
            },
          });
        }}
      >
        <FormTextField
          className="my-32 rounded-xl"
          name="email"
          type="email"
          label={<Trans message="Email" />}
          disabled={!!searchParamsEmail}
          invalid={isInvalid}
          required
        />
        <FormTextField
          className="mb-12 rounded-xl"
          name="password"
          type="password"
          label={<Trans message="Password" />}
          invalid={isInvalid}
          labelSuffix={
            <Link className={LinkStyle} to="/forgot-password" tabIndex={-1}>
              <Trans message="Forgot your password?" />
            </Link>
          }
          required
        />
        <FormCheckbox name="remember" className="mb-32 block">
          <Trans message="Stay signed in for a month" />
        </FormCheckbox>
        <Button
          className="block w-full rounded-xl"
          type="submit"
          variant="flat"
          color="primary"
          size="md"
          disabled={login.isPending}
        >
          <Trans message="Continue" />
        </Button>

      <div className="w-full relative mt-20 text-center before:absolute before:left-0 before:top-1/2 before:h-1 before:w-full before:-translate-y-1/2 before:bg-divider">
        <span className="relative z-10 bg-paper px-10 text-sm text-muted">
          OR
        </span>
      </div>
      </Form>
      <SocialAuthSection
        dividerMessage={
          social.compact_buttons ? (
            <Trans message="" />
          ) : (
            <Trans message="" />
          )
        }
      />
      </Modal>
      <Modal
        isOpen={isExistingRegisterModalOpen}
        onRequestClose={closeExistingRegisterModal}
        className="modal-content rounded-xl flex flex-col py-40 gap-20 w-full xl:w-1/3 lg:w-1/3 md:w-full sm:w-full"
        overlayClassName="modal-overlay w-full bg-black bg-opacity-70"
        style={{
          overlay: {
            zIndex: 1000,
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            position: 'fixed',
          },
          content: {
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#fff',
            position: 'fixed',
            zIndex: '9999',
          },
        }}
      >
        {/* Content for the existing register modal */}
        <button
          className="px-10 absolute top-4 left-4 text-gray-500 hover:text-gray-700"
          onClick={closeExistingRegisterModal}
        >
          X
        </button>
        <Form
        form={registerForm}
        onSubmit={async payload => {
          const isValid = await verify();
          if (isValid) {
            register.mutate(payload);
          }
        }}
      >
        <FormTextField
          className="mb-32"
          name="email"
          type="email"
          disabled={!!searchParamsEmail}
          label={<Trans message="Email" />}
          required
        />
        <FormTextField
          className="mb-32"
          name="password"
          type="password"
          label={<Trans message="Password" />}
          required
        />
        <FormTextField
          className="mb-32"
          name="password_confirmation"
          type="password"
          label={<Trans message="Confirm password" />}
          required
        />
        {auth?.registerFields ? <auth.registerFields /> : null}
        <PolicyCheckboxes />
        <Button
          className="block w-full rounded-xl"
          type="submit"
          variant="flat"
          color="primary"
          size="md"
          disabled={register.isPending || isVerifying}
        >
          <Trans message="Create account" />
        </Button>
      </Form>
      </Modal>
    </AuthLayout>
  );
}

function getDemoFormDefaults(siteConfig: SiteConfigContextValue) {
  if (siteConfig.demo.loginPageDefaults === 'randomAccount') {
    // random number between 0 and 100, padded to 3 digits
    const number = Math.floor(Math.random() * 100) + 1;
    const paddedNumber = String(number).padStart(3, '0');
    return {
      email: `admin@demo${paddedNumber}.com`,
      password: 'admin',
    };
  } else {
    return {
      email: siteConfig.demo.email ?? 'admin@admin.com',
      password: siteConfig.demo.password ?? 'admin',
    };
  }
}

function PolicyCheckboxes() {
  const {
    registration: {policies},
  } = useSettings();

  if (!policies) return null;

  return (
    <div className="mb-32">
      {policies.map(policy => (
        <FormCheckbox
          key={policy.id}
          name={policy.id}
          className="mb-4 block"
          required
        >
          <Trans
            message="I accept the :name"
            values={{
              name: (
                <CustomMenuItem
                  unstyled
                  className={() => LinkStyle}
                  item={policy}
                />
              ),
            }}
          />
        </FormCheckbox>
      ))}
    </div>
  );
}