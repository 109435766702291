import {Link} from 'react-router-dom';
import {ReactNode} from 'react';
import {AuthLayoutFooter} from './auth-layout-footer';
import {useIsDarkMode} from '@common/ui/themes/use-is-dark-mode';
import authBgSvg from './auth-bg.svg';
import {useTrans} from '@common/i18n/use-trans';
import {useSettings} from '@common/core/settings/use-settings';

interface AuthPageProps {
  heading?: ReactNode;
  subheading?: ReactNode;
  logimessage?: ReactNode;
  message?: ReactNode;
  children: ReactNode;
}
export function AuthLayout({heading, subheading, children, logimessage, message}: AuthPageProps) {
  const {branding} = useSettings();
  const isDarkMode = useIsDarkMode();
  const {trans} = useTrans();

  return (
    <main
      className="h-screen flex flex-col items-center pt-70 overflow-y-auto"
    >
      <div className="rounded-lg w-full mx-auto flex items-center flex-col sm:flex-row">
        <div className="w-full sm:w-1/2 p-8 flex justify-center">
          {/* Your image goes here */}
          <svg viewBox="0 0 24 24" aria-hidden="true" className="max-w-200"><g><path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"></path></g></svg>
        </div>
        <div className="w-full sm:w-1/2 p-8">
          {heading && <h2 className="my-4 mb-50 text-5xl font-bold text-gray-700">{heading}</h2>}
          {subheading && <h2 className="mb-20 font-semibold text-4xl">{subheading}</h2>}

          <div className="w-full xl:w-1/2 lg:w-2/3 md:w-full sm:w-full p-8 p-8">
            {children}
          {/* {logimessage && <div className="my-4 text-sm">{logimessage}</div>} */}
          </div>
        </div>
      </div>
      <AuthLayoutFooter />
    </main>
  );
}
